<template>
    <div>
        <a-row>
            <a-col :span="24">
                <a-row>

                    <a-col :span="24">
                        <a-form-model layout="inline" v-model="formSearch">
                            <a-form-model-item lable="">
                                <a-input style="width: 220px" placeholder="请输入检测项目名称进行搜索" v-model="formSearch.name" @keyup="Get_sample_itempage"></a-input>
                            </a-form-model-item>
                            <a-form-model-item lable="">
                                <a-radio-group v-model="formSearch.status" @change="Get_sample_itempage">
                                    <a-radio :value=1>使用中</a-radio>
                                    <a-radio :value=2>已停用</a-radio>
                                </a-radio-group>
                            </a-form-model-item>
                            <a-form-model-item lable="">
                                <a-button type="danger" @click="Clear()">清空搜索</a-button>
                                <!--                                <a-button type="primary" style="margin-left: 10px" v-if="(item_info.id != undefined && item_info.type != 0 )" @click="showDrawerNewItem({-->
                                <!--                        id:0,-->
                                <!--                        sample_category_id: '',-->
                                <!--                        executive_bz_id:'',-->
                                <!--                        jc_item_ids:'',-->
                                <!--                        })">{{Get_sample_item_list.length === 0 ?'新建':'维护'}}检验项目-->
                                <!--                                </a-button>-->
                            </a-form-model-item>
                        </a-form-model>
                    </a-col>

                    <!--                    <a-col :span="3" style="text-align: right">-->
                    <!--                        <a-button type="danger" @click="delClick()">移除所选项目</a-button>-->
                    <!--                    </a-col>-->
                    <a-col :span="24">
                        <a-space style="margin-bottom: -12px; background: #ebf8fe; border: none" class="btner">
                            <div class="btnerlist">
                                <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick()">
                                    <template slot="title">确认删除该项？</template>
                                    <i class="iconfont icon-yixiaohui" style="font-size: 15px; vertical-align: -1px"></i>移除所选项目
                                </a-popconfirm>
                            </div>
                            <div class="btnerlist" v-if="(item_info.id != undefined && item_info.type != 0 )" @click="showDrawerNewItem({
                        id:0,
                        sample_category_id: '',
                        executive_bz_id:'',
                        jc_item_ids:'',
                        })">
                                <i class="iconfont icon-weituokehuxinxiguanli"></i>{{Get_sample_item_list.length === 0
                                ?'新建':'维护'}}检验项目
                            </div>
                        </a-space>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <table class="layui-table" lay-size="sm">
                            <thead>
                            <tr>
                                <th>
                                    <a-checkbox v-model="all_check" @change="allChange"></a-checkbox>
                                </th>
                                <th @click="paixuclickup(1)" style="cursor: pointer;">
                                    <span>样品类型分类</span>
                                    <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou" :class="[data_name === 1 && data_sort  === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou" :class="[data_name === 1 && data_sort  === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                        </span>
                                    </div>
                                </th>
                                <th @click="paixuclickup(2)" style="cursor: pointer;">
                                    <span>检验项目</span>
                                    <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou" :class="[data_name === 2 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou" :class="[data_name === 2 && data_sort === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                        </span>
                                    </div>
                                </th>
                                <th @click="paixuclickup(3)" style="cursor: pointer;">
                                    <span>检验方法</span>
                                    <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou" :class="[data_name === 3 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou" :class="[data_name === 3 && data_sort === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                        </span>
                                    </div>
                                </th>
                                <th @click="paixuclickup(4)" style="cursor: pointer; width: 60px">
                                    <span>状态</span>
                                    <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou" :class="[data_name === 4 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou" :class="[data_name === 4 && data_sort === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                        </span>
                                    </div>
                                </th>
                                <th>标准值</th>
                                <th>单位</th>
                                <th>执行标准</th>
                                <th>执行标准编号</th>
                                <th>检验标准</th>
                                <th>检验标准编号</th>
                                <th>检验标准细则编号</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item,key) in Get_sample_item_list" :class="[item.check ? 'active':'']" @click="clickrow(key)">
                                <td>
                                    <a-checkbox v-model='item.check'></a-checkbox>
                                </td>
                                <td>{{item.sample_category_name}}</td>
                                <td>{{item.item_name}}</td>
                                <td>{{item.method_name}}</td>
                                <td>{{item.status_trun}}</td>
                                <td>{{item.bz_value}}</td>
                                <td>{{item.factor_unit_name}}</td>
                                <td>{{item.executive_name}}</td>
                                <td>{{item.executive_serial}}</td>
                                <td>{{item.check_name}}</td>
                                <td>{{item.check_serial}}</td>
                                <td>{{item.xz_serial }}</td>
                            </tr>
                            </tbody>
                        </table>
                        <a-pagination :current="page" :pageSize="pagesize" :total="count" v-if="count>pagesize" @change="onChange"/>
                    </a-col>
                </a-row>
            </a-col>
        </a-row>
        <div v-if="edit_infoNewItem">
            <NewItem :executive_serial="executive_serial" :zhixingbiaozhunname="zhixingbiaozhunname" :right_ids="right_ids" :edit_timeNewItem="edit_timeNewItem" :edit_infoNewItem="edit_infoNewItem" :item_info="item_info" :close="onCloseDrawerNewItem" :visible="drawer_visibleNewItem"></NewItem>
        </div>

    </div>
</template>

<script>

    import NewItem from './NewItem/NewItem.vue';

    export default {
        name: "sampletype",
        components: {NewItem},
        props: ["reload", "item_info", "deep_info"],
        data() {
            return {
                formSearch: {
                    name: '',
                    status: '',
                },
                right_ids: [],
                Get_sample_item_list: [],
                page: 1,
                count: 0,
                pagesize: 0,
                page_show: false,
                all_check: false,

                edit_timeNewItem: 0,
                edit_infoNewItem: false,
                drawer_visibleNewItem: false,
                data_name: 0,
                data_sort: 'asc',
                zhixingbiaozhunname: '',
                executive_serial: ''
            }
        },
        watch: {
            itemActiveChange() {
                let do_ = true
                this.Get_sample_item_list.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_check = do_
            },
            allChange(e) {
                this.Get_sample_item_list.map((item, key) => {
                    this.$set(this.Get_sample_item_list[key], 'check', this.all_check)
                })
            },


            $route: {
                handler() {
                    this.mountedDo()
                },
                deep: true
            },
        },
        mounted() {
            this.mountedDo()


            console.log('this.item_infothis.item_infothis.item_infothis.item_info', this.item_info)
        },
        methods: {
            itemActiveChange() {
                let do_ = true
                this.Get_sample_item_list.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_check = do_
            },
            allChange(e) {
                this.Get_sample_item_list.map((item, key) => {
                    this.$set(this.Get_sample_item_list[key], 'check', this.all_check)
                })
            },

            clickrow(key) {
                this.$set(this.Get_sample_item_list[key], 'check', !this.Get_sample_item_list[key].check)
                this.itemActiveChange()
            },

            mountedDo() {
                this.Get_sample_item()//列表
            },
            delClick() {
                let ids = []
                this.Get_sample_item_list.map((item) => {
                    console.log(987456, item)
                    if (item.check) {
                        ids.push(Number(item.jc_item_id))
                    }
                })
                if (ids.length < 1) {
                    layer.msg('请勾选需要操作的项目')
                    return
                }
                console.log(987456321, ids)

                this.$sa0.post({
                    url: this.$api('Del_sample_item'),
                    data: {
                        sample_category_id: this.item_info.id,
                        jc_item_ids: ids.join(','),
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_sample_item()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },

                    })
                })
            },
            // 搜索
            Clear() {
                this.formSearch = {
                    name: '',
                    status: '',
                }
                this.Get_sample_item();
            },
            //分页
            onChange(page) {
                this.page = page;
                this.Get_sample_item();
            },

            Get_sample_itempage() {
                this.page = 1
                this.Get_sample_item()
            },

            Get_sample_item() {
                if (this.deep_info !== "all") {
                    this.$sa0.post({
                        url: this.$api('Get_sample_item'),
                        data: {
                            sample_category_id: this.item_info.id,
                            name: this.formSearch.name,
                            status: this.formSearch.status,
                            page: this.page,
                        },
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                this.Get_sample_item_list = response.data.return.list.map((item) => {
                                    item.check = false
                                    return item;
                                });
                                this.count = Number(response.data.return.count);//分页
                                this.pagesize = Number(response.data.return.pagesize);//分页
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })

                } else {

                    //console.log("===========================================")
                    //console.log('Get_sample_item_all')
                    this.$sa0.post({
                        url: this.$api('Get_sample_item_all'),
                        data: {
                            // sample_category_id: this.item_info.id,
                            name: this.formSearch.name,
                            status: this.formSearch.status,
                            page: this.page,
                        },
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                this.Get_sample_item_list = response.data.return.list.map((item) => {
                                    item.check = false
                                    return item;
                                });

                                this.count = Number(response.data.return.count);//分页
                                this.pagesize = Number(response.data.return.pagesize);//分页

                                //console.log("===========================================")
                                //console.log(this.Get_sample_item_list)
                            },

                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })
                }
            },


            paixuclickup(data_name) {
                if (this.data_name !== data_name) {
                    this.data_name = data_name
                    this.data_sort = 'asc'
                } else {
                    if (this.data_sort === 'asc') {
                        this.data_sort = 'desc'
                    } else {
                        this.data_sort = 'asc'
                    }
                }

                if (this.deep_info !== "all") {
                    this.$sa0.post({
                        url: this.$api('Get_sample_item'),
                        data: {
                            sample_category_id: this.item_info.id,
                            name: this.formSearch.name,
                            status: this.formSearch.status,
                            page: this.page,
                            data_name: this.data_name,
                            data_sort: this.data_sort,
                        },
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                this.Get_sample_item_list = response.data.return.list
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })

                } else {

                    this.$sa0.post({
                        url: this.$api('Get_sample_item_all'),
                        data: {
                            name: this.formSearch.name,
                            status: this.formSearch.status,
                            page: this.page,
                            data_name: this.data_name,
                            data_sort: this.data_sort,
                        },
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                this.Get_sample_item_list = response.data.return.list
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })

                }

            },

            // 定义 打开抽屉时的 新建/编辑
            showDrawerNewItem(item) {

                // let id = 0
                // let count = 0
                // this.Get_sample_item_list.map((item, key) => {
                //     if (item.check) {
                //         this.edit_infoNewItem = item;
                //         id = item.sample_item_id
                //         count++
                //     }
                // })
                // if (!id) return layer.msg('请选择样品')
                // if (count !== 1) return layer.msg('只能选择一个样品')
                let right_ids = []
                this.Get_sample_item_list.map((item) => {
                    right_ids.push(Number(item.check_bz_id))
                    this.zhixingbiaozhunname = item.executive_name
                    this.executive_serial = item.executive_serial
                })
                this.right_ids = right_ids;

                console.log(55555,this.zhixingbiaozhunname)


                this.edit_infoNewItem = false

                setTimeout(() => {
                    this.edit_timeNewItem = new Date() / 1;
                    this.drawer_visibleNewItem = true;
                    this.edit_infoNewItem = true;
                })
            },
            // 定义 关闭抽屉时的
            onCloseDrawerNewItem() {
                this.Get_sample_item();
                this.drawer_visibleNewItem = false;
            },
        },
    }
</script>

<style scoped>
    /*******************w按钮区开始w*********************
        ***********************************************/
    .btner{
        width: 100%;
        background: #f3f3f3;
        padding: 5px 10px;
        font-size: 12px;
        color: #333;
        border: 1px solid #eeeeee;
        }

    .btnerlist{
        cursor: pointer;
        border-right: 1px solid #e9e8e8;
        height: 26px;
        line-height: 24px;
        padding: 0 12px
        }

    .btner:last-child{
        border: none
        }

    .isthis, .btnerlist:hover{
        background: #1592fe;
        color: #ffffff;
        border-radius: 4px;
        height: 26px;
        line-height: 24px;
        }

    .btnerlist i{
        color: #999999;
        font-size: 16px;
        margin-right: 3px;
        }

    .isthis i, .btnerlist:hover i{
        color: #ffffff !important
        }

    .isDel, .isDel:hover{
        background: #ff4d4f;
        border-radius: 4px;
        color: #ffffff !important
        }

    .isDel i, .isDel:hover, .isDel:hover i{
        color: #ffffff !important
        }

    .active{
        background: #ebf6ff;
        }

    .ant-table-column-sorter{
        display: inline-flex;
        flex-direction: column;
        margin-top: 0px;
        margin-left: 5px;
        cursor: pointer;
        width: 10px;
        vertical-align: middle;
        }

    .jiantou{
        color: #999999
        }

    .classname{
        color: #1890ff;
        }

    .fl{
        float: left
        }

    .active{ background: #ebf6ff}

    table tr{ cursor: pointer}


    /*******************w按钮区结束w*********************
    ***********************************************/
</style>

