<template>
    <!--添加分类-->
    <!-- 创建、编辑分类 -->
    <div class="createsmaplewrap1">
        <div>
            <div v-if="item_info">
                <CreatItem :create_do="create_do" :reload="reload" :item_info="item_info" :deep_info="deep_info"></CreatItem>
            </div>
        </div>
    </div>
</template>
<script>
    import CreatItem from "./creatitem/creatitem.vue";
    export default {
        components: {CreatItem},
        props: ["reload", "deep_info"],
        name: "item",
        data() {
            return {
                item_info: false,
                method_id: 0,
                item_type: 0,
                item_type1: 0,
                create_do: 1,
                formInline: {},
                labelCol: {span: 4},
                wrapperCol: {span: 14},
            };
        },
        watch: {
            $route: {
                handler() {
                    this.mountedDo();
                },
                deep: true,
            },
        },
        mounted() {
            this.mountedDo();
        },
        methods: {
            mountedDo() {
                this.item_type = 0;
                this.item_type1 = 0;
                //console.log("666666666666666666666666666666666666666")
                //console.log(this.deep_info)
            },
            showAll(id) {
                // this.method_id = id
                // console.log(10, this.method_id)
                this.$sa0
                    .post({
                        url: this.$api("Get_samplecategory_detail"),
                        // data: {
                        //     id: id,
                        // },
                    })
                    .then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                let info = response.data.sample_category;
                                this.item_info = info;
                                this.item_type = Number(info.type);
                                this.create_do = 0;
                            },
                            error: (response) => {
                                layer.msg(response.message);
                            },
                        });
                    });
            },
            getMethodInfo(id) {
                this.method_id = id
                console.log('---------------------------------------------all', id)
                this.$sa0
                    .post({
                        url: this.$api("Get_samplecategory_detail"),
                        data: {
                            id: id,
                        },
                    })
                    .then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                let info = response.data.sample_category;
                                this.item_info = info;
                                this.item_type = Number(info.type);
                                this.create_do = 0;
                            },
                            error: (response) => {
                                layer.msg(response.message);
                            },
                        });
                    });
            },

            // delThis() {
            //     this.$sa0.post({
            //         url: this.$api('del_samplecategory'),
            //         data: {
            //             id: this.method_id,
            //         }
            //     }).then((response) => {
            //         this.$sa0.response({
            //             response: response.data,
            //             then: (response) => {
            //                 layer.msg(response.message)
            //                 //this.$router.push('/taskability/taskability/0')
            //             },
            //             error: (response) => {
            //                 layer.msg(response.message)
            //             },
            //         })
            //     })
            // },

        },
    };
</script>

<style scoped>
    .createsmapletit{
        font-size: 20px;
        font-weight: bold;
        }

    .ml20{
        margin-left: 20px;
        }

    .mt20{
        margin-top: 20px;
        }

    .w200{
        width: 200px;
        }

    .button_wrapper{
        position: absolute;
        width: 1000px;
        text-align: right;
        top: 40px;
        text-align: left;
        background: #fafafa;
        padding: 10px 20px;
        }

    .edit_table_wrapper{
        position: absolute;
        left: 280px;
        right: 10px;
        bottom: 0;
        top: 0;
        }

    .creatlist{
        margin-top: 100px
        }
</style>
