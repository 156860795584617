<template>
    <!--:visible="visible"  赋值 显示变量-->
    <!--@close="onClose"    赋值 关闭函数-->
    <!--    :title="`${edit_infoNewItem.id===0?'新建':'维护'}检测项目`"-->
    <a-drawer title="增加检测项目" width="100%" placement="right" :closable="true" :visible="visible" @close="onClose">
        <div>
            <a-row style="padding-bottom: 25px">
                <a-form-model layout="inline" v-model="formCreat">
                    <a-col :span="6">
                        <a-form-model-item style="padding: 0; margin: 0">执行标准：</a-form-model-item>
                        <a-form-model-item>
                            <!--                            <a-select v-model="formCreat.name" style="width: 320px;" @change="check_bz_idChange">-->
                            <!--                                <a-select-option :value="item.id" v-for="(item,key) in Executive_bz_list_list">-->
                            <!--                                    {{item.name}}-->
                            <!--                                </a-select-option>-->
                            <!--                            </a-select>-->
                            <a-auto-complete v-model="formCreat.name" :data-source="dataSource.Name" placeholder="请输入执行标准" :filter-option="filterOption" @focus="onSearch1()" style="width: 320px;" @change="check_bz_idChange"/>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-model-item style="padding: 0; margin: 0">执行标准编号：</a-form-model-item>
                        <a-form-model-item>{{formCreat.serial}}</a-form-model-item>
                        <a-form-model-item lable="">
                            <a-button type="primary" v-if="this.Right_list != ''" @click="doCreat()">保存</a-button>
                        </a-form-model-item>
                    </a-col>
                </a-form-model>
            </a-row>
            <!----------切换开始---------------------------->
            <div class="nav-tab">
                <a-tabs default-active-key="1" type="card" v-model="tab_active">
                    <a-tab-pane key="1" tab="按标准分类查看"></a-tab-pane>
                    <a-tab-pane key="2" tab="按方法分类查看"></a-tab-pane>
                </a-tabs>
                <div class="wraper">
                    <div class="wrap-mainer">

                        <div class="sidebar">

                            <div v-if="tab_active === '1'" class="tree_wrapperitem">
                                <div class="treetitle">标准分类</div>
                                <ltree ref="ltreeref" w="230px" ot="only" :dt="0" :it="1" :data="tree" all="all1" :all_menu="all_menu" :menu="menu_arr" :callback="treeCallback" :renameDo="renameDo"></ltree>
                            </div>

                            <div v-if="tab_active === '2'" class="tree_wrapperitem">
                                <div class="treetitle">方法分类</div>
                                <Tree1 ref="ltreeref" w="230px" ot="only" :dt="0" :it="1" :data="tree1" all="all1" :all_menu="all_menu" :menu="menu_arr" :callback="treeCallback1" :renameDo="renameDo"></Tree1>
                            </div>

                        </div>

                        <div v-if="item_show" class="wrap-container">
                            <a-row style="padding: 0 15px">
                                <a-col :span="13">
                                    <a-form-model layout="inline" v-model="formSearch_left">
                                        <a-form-model-item lable="" :wrapper-col="{ span: 24,}">
                                            <a-input v-model="formSearch_left.item_name" placeholder="输入检测项目名称、拼音首字母进行搜索" style="width: 300px" @keyup="Jc_item_list()"></a-input>
                                        </a-form-model-item>
                                        <a-form-model-item lable="" :wrapper-col="{ span: 24,}">
                                            <a-button type="primary" @click="Clear()">清空搜索</a-button>
                                        </a-form-model-item>
                                    </a-form-model>
                                    <div style="height: 600px; overflow-y: auto">
                                        <table class="layui-table" lay-size="sm">
                                            <thead>
                                            <tr>
                                                <th>
                                                    <a-checkbox v-model="all_check" @change="allChange"></a-checkbox>
                                                </th>
                                                <th>检验项目名称</th>
                                                <th>检验标准分类</th>
                                                <th>方法类别</th>
                                                <th>检验方法</th>
                                                <th>检验标准细则</th>
                                                <th>标准值</th>
                                                <th>单位</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(item,key) in Jc_item_list_list" :key="key" v-show="llshow(item)" :class="[item.check ? 'active':'']" @click="clickrow(key)">
                                                <td>
                                                    <a-checkbox v-model='item.check'></a-checkbox>
                                                </td>
                                                <td>{{item.item_name}}</td>
                                                <td>{{item.det_item_name}}</td>
                                                <td>{{item.method_category_name}}</td>
                                                <td>{{item.method_name}}</td>
                                                <td>{{item.xz_serial}}</td>
                                                <td>{{item.bz_value}}</td>
                                                <td>{{item.factor_unit_name}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <a-pagination :current="page" :pageSize="pagesize" :total="count" v-if="count>pagesize" @change="onChange"/>
                                    </div>

                                </a-col>
                                <a-col :span="1" style="position: relative;  height:600px">
                                    <div class="csconmid-btn">
                                        <div class="csconmid-btn-b" @click="allrightclick()">
                                            <a-icon type="double-right"/>
                                        </div>
                                        <div class="csconmid-btn-b" @click="selectrightclick(true)">
                                            <a-icon type="right"/>
                                        </div>
                                        <div class="csconmid-btn-b" @click="selectleftclick()">
                                            <a-icon type="left"/>
                                        </div>
                                        <div class="csconmid-btn-b" @click="allleftclick()">
                                            <a-icon type="double-left"/>
                                        </div>
                                    </div>
                                </a-col>
                                <a-col :span="10">
                                    <a-form-model layout="inline" v-model="formSearch_right">
                                        <a-form-model-item lable="" :wrapper-col="{ span: 24,}">
                                            <a-input placeholder="输入检测项目名称、拼音首字母进行搜索" style="width: 300px" v-model="formSearch_right.item_name" @keyup="Get_sample_item()"></a-input>
                                        </a-form-model-item>
                                        <a-form-model-item lable="" :wrapper-col="{ span: 24,}">
                                            <a-button type="primary" @click="ClearRight()">清空搜索</a-button>
                                        </a-form-model-item>
                                    </a-form-model>
                                    <div style="height: 600px; overflow-y: auto">
                                        <table class="layui-table" lay-size="sm">
                                            <thead>
                                            <tr>

                                                <th>
                                                    <a-checkbox v-model="all_check1" @change="allChange1"></a-checkbox>
                                                </th>
                                                <th>检验项目</th>
                                                <th>方法类别</th>
                                                <th>检验方法</th>
                                                <th>检验细则</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="( item,key) in Right_list" :key="key" :class="[item.check ? 'active':'']" @click="clickrow1(key)">
                                                <td>
                                                    <a-checkbox v-model='item.check'></a-checkbox>
                                                </td>
                                                <td>{{item.item_name}}</td>
                                                <td>{{item.method_category_name}}</td>
                                                <td>{{item.method_name}}</td>
                                                <td>{{item.check_serial}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </a-col>
                            </a-row>
                        </div>

                    </div>
                </div>


            </div>
            <!----------切换结束---------------------------->
        </div>
    </a-drawer>
</template>

<script>
    import Tree1 from './tree1/ltree.vue'
    import ltree from './ltree/ltree.vue';

    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_infoNewItem', 'item_info', 'right_ids', 'zhixingbiaozhunname', 'executive_serial'],
        components: {ltree, Tree1},
        data: () => ({
            menu_arr: [],
            all_menu: [],
            tree: [],
            tree1: [],
            menu: [],
            item_show: false,
            tab_active: '1',
            method_id: 0,
            method_id1: 0,
            formCreat: {
                name: '',
                erial: '',
                id: ''
            },
            formSearch_left: {
                item_name: '',
            },
            formSearch_right: {
                item_name: '',
            },
            Executive_bz_list_list: [],
            dataSource: {
                Name: [],//执行标准名字
                id: [],
                serial: []
            },
            page: 1,
            count: 0,
            pagesize: 0,
            Jc_item_list_list: [],
            Right_list: [],
            list: [],

            left_list: [],
            left_search: '',
            right_list: [],
            right_search: '',
            all_check: false,
            all_check1: false,
            all_check_r: false,
            allfactorData: [],
            selectfactorData: [],
            sort_info: {
                data_name: 1,
                data_sort: 1,
            },
            sort_info1: {
                data_name: 1,
                data_sort: 1,
            },


        }),
        watch: {
            itemActiveChange() {
                let do_ = true
                this.Jc_item_list_list.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_check = do_
            },
            allChange(e) {
                this.Jc_item_list_list.map((item, key) => {
                    this.$set(this.Jc_item_list_list[key], 'check', this.all_check)
                })
            },


            itemActiveChange1() {
                let do_ = true
                this.Right_list.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_check1 = do_
            },
            allChange1(e) {
                this.Right_list.map((item, key) => {
                    this.$set(this.Right_list[key], 'check', this.all_check1)
                })
            },

            // all_check() {
            //     this.left_list.map((item, key) => {
            //         this.$set(this.left_list[key], 'check', this.all_check)
            //     })
            // },
            // all_check_r() {
            //     this.right_list.map((item, key) => {
            //         this.$set(this.right_list[key], 'check', this.all_check_r)
            //     })
            // },
        },
        mounted() {
            this.Jc_item_list(true)//检测项目列表
            // this.Executive_bz_list()//执行标准列表
            this.Get_sample_item()//列表
            this.GMethod_category_alllist()
            this.Method_category_alllist()
            setTimeout(() => {
                this.tree_show = true
            }, 100);
            this.treeCallback([{
                id: "all"
            }])
            console.log(999, this.zhixingbiaozhunname)

            this.formCreat.name = JSON.parse(JSON.stringify(this.zhixingbiaozhunname))
            this.formCreat.serial = JSON.parse(JSON.stringify(this.executive_serial))
        },
        methods: {

            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                );
            },

            onSearch1(searchText) {
                this.$sa0.post({
                    url: this.$api('Get_executive_bz_list'),
                    data: {
                        search_value: searchText,
                        ziduan_param: 'name'
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.dataSource = response.data.list
                            if (response.data.list.length > 0) {
                                if (this.formCreat.name === '') {
                                    this.dataSource.Name = response.data.list.map(i => {
                                        return i.param_name
                                    })
                                } else {
                                    this.dataSource.Name = response.data.list.filter(item => {
                                        return item.param_name
                                    }).map(i => {
                                        return i.param_name
                                    })
                                }
                            } else {
                                this.dataSource.Name = []
                            }
                            ;

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            check_bz_idChange(e) {
                this.dataSource.map((item) => {
                    if (e === item.param_name) {
                        this.formCreat.serial = item.serial
                        this.formCreat.id = item.id
                    }
                })
            },


            itemActiveChange() {
                let do_ = true
                this.Jc_item_list_list.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_check = do_
            },
            allChange(e) {
                this.Jc_item_list_list.map((item, key) => {
                    this.$set(this.Jc_item_list_list[key], 'check', this.all_check)
                })
            },

            itemActiveChange1() {
                let do_ = true
                this.Right_list.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_check1 = do_
            },
            allChange1(e) {
                this.Right_list.map((item, key) => {
                    this.$set(this.Right_list[key], 'check', this.all_check1)
                })
            },


            clickrow(key) {
                this.$set(this.Jc_item_list_list[key], 'check', !this.Jc_item_list_list[key].check)
                this.itemActiveChange()
            },


            clickrow1(key) {
                this.$set(this.Right_list[key], 'check', !this.Right_list[key].check)
                this.itemActiveChange1()
            },


            //tree开始
            menuarrmaker() {
                this.all_menu = [{
                    name: '新建文件夹',
                    show: -1,
                    func: () => {
                        console.log('根目录 新建文件夹')
                        this.creatfileall()  //全部新建文件夹
                    }
                }]
                this.menu_arr = [{
                    name: '重命名',
                    show: -1,
                    func: (item) => {
                        this.$refs['ltreeref'].renameActive(item.id)
                    }
                }, {
                    name: '新建内容',
                    show: 0, //  -1 全部 0-文件夾 1-内容
                    c_show: 1, //  -1 全部 0-文件夾 1-内容
                    func: (item) => {
                        console.log(item, '新建内容')
                        this.creatcontant(item)
                    }
                }, {
                    name: '新建文件夹',
                    show: 0,
                    c_show: 0,
                    func: (item) => {
                        console.log(item, '新建文件夹')
                        this.creatfilemain(item)   //下面的新建文件夹
                    }
                },
                    {
                        name: '创建副本',
                        show: -1,
                        func: (item) => {
                            console.log(item, '创建副本')
                        }
                    }, {
                        name: '上移',
                        show: -1,
                        func: (item) => {
                            console.log(item, '上移')

                            this.UPitemlist(item)
                        }
                    }, {
                        name: '下移',
                        show: -1,
                        func: (item) => {
                            console.log(item, '下移')

                            this.Downitemlist(item)
                        }
                    },
                    {
                        name: '删除',
                        show: -1,
                        func: (item) => {
                            console.log(item, '删除')
                            this.delclick(item)
                        }
                    },]
            },

            // 获取树执行标准列表
            GMethod_category_alllist(id = 0) {
                this.$sa0.post({
                    url: this.$api('Detitemalllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.tree = this.makeList(response.data.list);
                            if (id !== 0) {
                                setTimeout(() => {
                                    // this.$refs['ltreeref'].openGroup()
                                    this.$refs['ltreeref'].renameActive(Number(id))
                                }, 10000)
                            }
                            this.menuarrmaker()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            Method_category_alllist(id = 0) {
                this.$sa0.post({
                    url: this.$api('Method_category_alllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.tree1 = this.makeList(response.data.list);
                            //console.log("====================================")
                            //console.log(this.tree1)
                            if (id !== 0) {
                                setTimeout(() => {
                                    // this.$refs['ltreeref'].openGroup()
                                    this.$refs['ltreeref'].renameActive(Number(id))
                                }, 10000)
                            }
                            this.menuarrmaker()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            renameDo(item, name) {
                this.$sa0.post({
                    url: this.$api('Rename_detitem'),
                    data: {
                        name: name,
                        id: item.id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.rename = false;
                            layer.msg(response.message)
                            this.GMethod_category_alllist()
                            this.$refs['ltreeref'].renameActive('no')
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            creatfileall() {
                this.$sa0.post({
                    url: this.$api('Create_detitem'),
                    data: {
                        name: '新建文件夹',
                        pid: 0,
                        type: 0,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist(response.data.id)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            creatfilemain(item) {
                this.$sa0.post({
                    url: this.$api('Create_detitem'),
                    data: {
                        name: '新建文件夹1',
                        pid: item.id,
                        type: 0,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist(response.data.id)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            creatcontant(item) {
                this.$sa0.post({
                    url: this.$api('Create_detitem'),
                    data: {
                        name: '新建执行标准名称',
                        pid: item.id,
                        type: 1,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist(response.data.id)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            UPitemlist(item) {
                this.$sa0.post({
                    url: this.$api('Set_detitemsort'),
                    data: {
                        det_item_id: item.id,
                        type: 1
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            Downitemlist(item) {
                this.$sa0.post({
                    url: this.$api('Set_detitemsort'),
                    data: {
                        det_item_id: item.id,
                        type: 2
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            delclick(item) {
                this.$confirm({
                    title: '确认删除该项?',
                    content: '',
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk: () => {
                        let data = {
                            id: item.id,
                        }
                        this.$sa0.post({
                            url: this.$api('Del_detitem'),
                            data: data,
                        }).then((response) => {
                            this.$sa0.response({
                                response: response.data,
                                then: (res) => {
                                    layer.msg(res.message)
                                    this.GMethod_category_alllist()
                                },
                                error: (res) => {
                                    layer.msg(res.message)
                                }
                            })
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },
            treeCallback(item) {
                //console.log(123, item)
                let info = item[item.length - 1]
                if (info.id !== 'all' && info.type === 0) return
                this.deep_info = info
                this.item_show = false
                setTimeout(() => {
                    this.item_show = true
                    setTimeout(() => {
                        this.getMethodInfo(info.id)
                    })
                })
            },

            treeCallback1(item) {
                //console.log(123, item)
                let info = item[item.length - 1]
                if (info.id !== 'all' && info.type === 0) return
                this.deep_info = info
                this.item_show = false
                setTimeout(() => {
                    this.item_show = true
                    setTimeout(() => {
                        this.getMethodInfo1(info.id)
                    })
                })
            },
            getMethodInfo(id) {
                this.method_id = id === 'all' ? '' : id
                this.Jc_item_list()
            },

            getMethodInfo1(id) {
                this.method_id1 = id === 'all' ? '' : id
                //console.log(456, this.method_id1)
                this.Jc_item_list()
            },

            makeList(list) {
                return list.map((item) => {
                    return {
                        'id': Number(item.id),
                        "type": item.type,
                        "name": item.name,
                        "children": item.child.length === 0 ? [] : this.makeList(item.child),
                        "content": {
                            "pid": item.pid,
                        }
                    }
                })
            },
            //tree结束

            llshow(item) {
                let search = this.formSearch_left.item_name;
                if (search == '') return true;
                if (item.item_name.toLowerCase().indexOf(search.toLowerCase()) !== -1) return true;
                if (item.method_name.toLowerCase().indexOf(search.toLowerCase()) !== -1) return true;
                return false;
            },

            doCreat() {
                //console.log(this.weituodan_info.id,item.id);
                // let list = []
                // this.Right_list.map((item) => {
                //     list.push(item.id)
                // })
                // let data = null;
                let data = {
                    sample_category_id: this.item_info.id,
                    executive_bz_id: this.formCreat.id,
                    //jc_item_ids: list.join(','),
                }
                //console.log("==============================================")
                //console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Create_sample_item'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg('已保存')
                            this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
            selectrightclick1(type = false) {
                let list = []
                let del_list = []
                //console.log(this.Jc_item_list_list)
                this.Jc_item_list_list.map((item) => {
                    if (item.check) {
                        list.push(item)
                    } else {
                        del_list.push(item)
                    }
                })
                this.Right_list = [
                    ...this.Right_list,
                    ...list,
                ]
                let ilist = []
                this.Right_list.map((item) => {
                    if (item.id) {
                        ilist.push(item.id)
                    }
                })
                this.Jc_item_list_list = del_list
                //console.log(this.Jc_item_list_list)
            },
            selectrightclick(type = false) {
                let list = []
                let del_list = []
                this.Jc_item_list_list.map((item) => {
                    if (item.check) {
                        list.push(item)
                    } else {
                        del_list.push(item)
                    }
                })
                this.Right_list = [
                    ...this.Right_list,
                    ...list,
                ]
                let ilist = []
                this.Right_list.map((item) => {
                    if (item.id) {
                        ilist.push(item.id)
                    }
                })
                let data = null;
                data = {
                    sample_category_id: this.item_info.id,
                    jc_item_ids: ilist.join(','),
                }
                //console.log("==============================================")
                //console.log(JSON.stringify(data))
                if (type) {
                    this.$sa0.post({
                        url: this.$api('Add_sample_item_jcitemid'),
                        data: data,
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (res) => {
                                layer.msg('已增加')
                                // this.close()
                            },
                            error: (res) => {
                                layer.msg(res.message)
                            }
                        })
                    })
                }

                this.Jc_item_list_list = del_list
                //console.log()
                //console.log(JSON.stringify(this.Right_list))
            },

            allrightclick() {
                //console.log('=================================================')
                this.Right_list = [
                    ...this.Right_list,
                    ...this.Jc_item_list_list,
                ]
                this.Jc_item_list_list = []
            },

            selectleftclick() {
                let list = [];
                let del_list = [];
                this.Right_list.map((item) => {
                    if (!item.check) {
                        list.push(item)
                    } else {
                        del_list.push(item)
                    }
                })
                this.Jc_item_list_list = [
                    ...del_list,
                    ...this.Jc_item_list_list,
                ]
                this.Right_list = list
                // this.selectfactorData.map((item) => {
                //     if (del_ids.indexOf(Number(item.id)) === -1) {
                //         ids.push(Number(item.id))
                //     }
                // })
                // this.selectrightclick(ids, 2)
            },
            allleftclick() {
                this.Jc_item_list_list = [
                    ...this.Right_list,
                    ...this.Jc_item_list_list,
                ]
                this.Right_list = []
            },

            ClearRight() {
                this.formSearch_right = {
                    item_name: '',
                }
                this.Get_sample_item();
            },
            Get_sample_item() {
                this.$sa0.post({
                    url: this.$api('Get_sample_item'),
                    data: {
                        sample_category_id: this.item_info.id,
                        name: this.formSearch_right.item_name,
                        status: 1,
                    },

                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            //this.Get_sample_item_list = response.data.list;
                            this.Right_list = response.data.return.list.map((item) => {
                                item.check = false
                                return item;
                            });

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            Clear() {
                this.formSearch_left = {
                    item_name: '',
                }
                this.Jc_item_list();
            },

            // 分页
            onChange(page) {
                this.page = page;
                this.Jc_item_list();
            },

            Jc_item_list(type = false) {
                let method_category_id = ''
                let check_bz_id = ''
                if (this.tab_active === '1') {
                    check_bz_id = this.method_id
                } else {
                    method_category_id = this.method_id1
                }
                // this.tab_active === '1' ? check_bz_id = this.method_id : method_category_id = this.method_id1
                console.log('----------------------------------------', {
                    item_name: this.formSearch_left.item_name,
                    sample_category_id: this.item_info.id,
                    page: this.page,
                    // check_bz_id:this.method_id,
                    method_category_id: method_category_id,
                    check_bz_id: check_bz_id,
                })
                this.$sa0.post({
                    url: this.$api('Jc_item_list'),
                    data: {
                        item_name: this.formSearch_left.item_name,
                        sample_category_id: this.item_info.id,
                        page: this.page,
                        // check_bz_id:this.method_id,
                        method_category_id: method_category_id,
                        check_bz_id: check_bz_id,

                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Jc_item_list_list = response.data.return.list.map((item) => {
                                //console.log(item)
                                if (this.right_ids.indexOf(item.id) !== -1) {
                                    item.check = true
                                } else {
                                    item.check = false
                                }
                                return item;
                            });
                            this.count = Number(response.data.return.count);//分页
                            this.pagesize = Number(response.data.return.pagesize);//分页
                            if (type) {
                                this.selectrightclick()
                            } else {
                                this.selectrightclick1()
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //执行标准、执行编号


            // Executive_bz_list() {
            //     this.$sa0.post({
            //         url: this.$api('Executive_bz_list'),
            //         data: {},
            //     }).then((response) => {
            //         this.$sa0.response({
            //             response: response.data,
            //             then: (response) => {
            //                 this.Executive_bz_list_list = response.data.list;
            //                 if (this.Executive_bz_list_list.length) {
            //                     this.formCreat.name = this.Executive_bz_list_list[0].id
            //                     this.check_bz_idChange(this.formCreat.name)
            //                 }
            //             },
            //             error: (response) => {
            //                 layer.msg(response.message)
            //             },
            //         })
            //     })
            // },
            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },

        }
    }
</script>

<style scoped>


    .wraper{
        width: 100%;
        height: 100%;
        display: flex;
        }

    .wrap-mainer{
        flex: 1;
        display: flex;
        }

    .sidebar{
        width: 200px;
        display: flex;
        flex-direction: column
        }

    .wrap-container{
        flex: 1;
        overflow-y: auto;
        }

    .tree_wrapperitem{
        position: absolute;
        height: 620px;
        /*overflow-y: auto;*/
        margin-left: 0px;
        border: 1px solid #eeeeee;
        }

    .wraper{
        width: 100%;
        height: 100%;
        }

    .wrap-mainer{
        display: flex
        }

    .wrap-container{
        flex: 1;
        margin-left: -100px
        }

    .sidebar{
        width: 330px;
        }

    .treeside{
        position: fixed;
        width: 230px;
        height: calc(100vh - 110px);
        border: 1px solid #eeeeee;
        left: 0;
        border-left: none
        }

    .treetitle{
        width: 230px;
        line-height: 40px;
        font-size: 14px;
        font-weight: bold;
        background: #f9f9f9;
        padding-left: 10px;
        }

    .nav-tab{
        width: 100%;
        }

    .ant-tabs .ant-tabs-left-bar{
        /*float: right !important;*/
        margin-right: -1px;
        margin-bottom: 0;
        border-right: 1px solid #e8e8e8;
        /*width: 100% !important;*/
        }

    .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active{
        margin-right: -1px;
        padding-right: 40px !important;
        font-weight: bold;
        }

    .cscontainer{
        width: 100%;
        display: flex;
        height: 100%
        }

    .csconmid{
        width: 100px;
        position: relative
        }

    .csconleft{
        width: 400px;
        border: 1px solid #FAFAFA;
        padding: 10px;
        overflow-y: auto;
        height: 800px
        }

    .csconmid-btn{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)
        }

    .csconmid-btn .csconmid-btn-b{
        width: 30px;
        height: 30px;
        background: #1890ff;
        color: #ffffff;
        text-align: center;
        line-height: 25px;
        margin: 10px;
        border-radius: 2px;
        cursor: pointer
        }

    #components-form-demo-validate-other .dropbox{
        height: 180px;
        line-height: 1.5;
        }

    .active{ background: #ebf6ff}
</style>
